<template>
  <div class="container col-md-4 ">
    <div class="card align-self-center p-2">
      <h3 class="mb-4">Создание роли</h3>
      <form id="create-user-form" @submit.prevent="createRole">
        <div class="form-group row mb-2">
          <label class="col-sm-4 col-form-label">Роль</label>
          <div class="col-sm-8">
            <input type="text" class="form-control" pattern=".{4,}" required title="Минимум 4 символа"
            v-model="role.role"
            name="role"
            placeholder="Введите роль"
            >
          </div>
        </div>
        <div class="btn-toolbar justify-content-between">
          <button class="btn btn-outline-success float-left" v-on:click="navigate()">
            Вернуться назад
          </button>
          <button class="btn btn-success float-right" type="submit">
            Создать
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { server } from "@/utils/helper";
import router from "@/router";

export default {
  data() {
    return {
      role: {},
    };
  },
  methods: {
    createRole() {
      let roleData = {
        role: this.role.role
      };
      this.__submitToServer(roleData);
    },
    __submitToServer(data) {
      axios.post(`${server.baseURL}/admin/roles/create`, data).then(() => {
        router.push({ name: "Roles" });
      });
    },
    navigate() {
      router.push({ name: 'Roles' })
   }
  },
};
</script>
