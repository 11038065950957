<template>
  <p class="paragraphTEXT" v-html="entity.text"></p>
</template>

<style>
.paragraphTEXT .ql-align-justify{
  text-indent: 2rem;
  margin-top: 1rem;
}

.paragraphTEXT ul .ql-align-justify {
  text-indent: 0em;
  margin-top: 1rem;
}

.paragraphTEXT ol .ql-align-justify {
  text-indent: 0em;
  margin-top: 1rem;
}
</style>

<script>
export default {
  props: {
    entity: {
      type: Object,
      required: false
    }
  },
}
</script>