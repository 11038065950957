<template>
  <div class="container col-md-12 ">
    <div class="card align-self-center p-2">
      <button class="btn btn-outline-success float-left mb-2" v-on:click="navigate()">
        Вернуться назад
      </button>
      <h3 class="mb-4">Создание сущности {{type}}</h3>
      <form id="create-user-form" @submit.prevent="createEntity">
        <div class="form-group row mb-2"
        v-if="type=='blog' || type=='extrablog' || type=='card' || type=='miniblog' || type=='accordion' || type=='gallery' || type=='files' || type=='tab'">
          <label class="col-sm-2 col-form-label">Заголовок</label>
          <div class="col-sm-10">
            <input type="text" class="form-control" required
            v-model="entity.title" name="title" placeholder="Введите заголовок">
          </div>
        </div>
        <div class="form-group row mb-2"
        v-if="type=='blog' || type=='extrablog'">
          <label class="col-sm-2 col-form-label">Дата</label>
          <div class="col-sm-10">
            <input type="date" class="form-control" required
            v-model="entity.date" name="date" placeholder="Выберите дату">
          </div>
        </div>
        <div class="form-group row mb-2"
        v-if="type=='blog' || type=='extrablog' || type=='card' || type=='miniblog' || type=='gallery'">
          <label class="col-sm-2 col-form-label">Изображение</label>
          <div class="col-sm-10 d-flex">
            <input id="photo" type="file" accept="image/*" required class="form-control me-2" name="photo">
            <a class="btn btn-success float-right" v-on:click="pushPhoto()">
              <i class="bi bi-paperclip"></i>
            </a>
          </div>
        </div>
        <div class="form-group row mb-2"
        v-if="type=='blog' || type=='extrablog' || type=='card' || type=='miniblog' || type=='accordion' || type=='text' || type=='tab' || type=='gallery'">
          <label class="col-sm-2 col-form-label">Основной текст</label>
          <div class="col-sm-10">
            <VueEditor class="form-control text-control"
            v-model="entity.text" name="text" placeholder="Введите текст"/>
          </div>
        </div>
        <div class="form-group row mb-2 detail-control"
        v-if="type=='blog' || type=='extrablog' || type=='card'">
          <label class="col-sm-2 col-form-label">Подробный текст</label>
          <div class="col-sm-10">
            <VueEditor class="form-control detail-control-edit"
            v-model="entity.detail" name="detail" placeholder="Введите текст"/>
          </div>
        </div>
        <hr/>
        <div class="form-group row mb-2">
          <label class="col-sm-2 col-form-label">Контейнер</label>
          <div class="col-sm-10">
            <select class="form-select" v-model="entity.container" name="container" required>
              <option v-for="container in containers" :value="container.group" :key="container.id">
                {{ container.group }}
              </option>
            </select>
          </div>
        </div>
        <hr/>
        <div class="form-group row mb-2">
          <label class="col-sm-2 col-form-label"><strong>Вложения</strong></label>
          <div class="col-sm-10">
            <form id="create-attach-form" @submit.prevent="pushAttach">
              <div class="form-group row m-2">
                <label class="col-sm-2 col-form-label">Заголовок</label>
                <input type="text" id="attachTitle" class="col-sm-10 form-control"/>
              </div>
              <div class="form-group row m-2">
                <label class="col-sm-2 col-form-label">Тип</label>
                <select class="col-sm-10 form-select" id="attachType">
                  <option value="img" selected>img</option>
                  <option value="doc">doc</option>
                  <option value="xls">xls</option>
                  <option value="pdf">pdf</option>
                  <option value="ppt">link</option>
                  <option value="zip">zip</option>
                  <option value="link">link</option>
                </select>
              </div>
              <div class="form-group row m-2">
                <label class="col-sm-2 col-form-label">Путь</label>
                <input type="text" id="attachPath" class="col-sm-10 form-control"/>
              </div>
              <div class="d-flex mb-2 justify-content-between">
                <input type="reset" class="btn me-2 btn-outline-danger" value="Сбросить">
                <input type="submit" class="btn btn-outline-success" value="Вложить">
              </div>
            </form>
          </div>
        </div>
        <hr/>
        <div class="btn-toolbar justify-content-between">
          <button class="btn btn-outline-success float-left" v-on:click="navigate()">
            Вернуться назад
          </button>
          <button class="btn btn-success float-right" type="submit">
            Создать
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { server } from "@/utils/helper";
import router from "@/router";
import {VueEditor} from 'vue3-editor'

export default {
  data() {
    return {
      arrAttaches: [],
      containers: [],
      entity: {},
      file: '',
      type: ''
    };
  },
  created() {
    this.type = this.$route.params.type;
    this.fetchContainers();
  },
  methods: {
    fetchContainers() {
      axios
        .get(`${server.baseURL}/admin/management/containers`)
        .then(data => (this.containers = data.data));
    },
    pushAttach() {
      if (document.querySelector('#attachPath').value) {
        this.arrAttaches.push(
          {
            title: document.querySelector('#attachTitle').value,
            type: document.querySelector('#attachType').value,
            path: document.querySelector('#attachPath').value
          }
        )
        document.querySelector('#create-attach-form').reset()
      }
    },
    createAttaches(idEntity) {
      this.arrAttaches.forEach( async (element) => {
        let attachData = {
          title: element.title,
          type: element.type,
          path: element.path,
          entity: idEntity
        };
        await axios.post(`${server.baseURL}/admin/management/createattach`, attachData);
      });
    },
    pushPhoto() {
      this.file = document.querySelector('#photo').files[0];
      let formData = new FormData();
      formData.append('image', this.file);
      axios.post(`${server.baseURL}/files`,
        formData,
        {
          headers: {'Content-Type': 'multipart/form-data'}
        }).then(response => {
          this.entity.photo = response.data.data.filename;
        }).catch(response => {
          return console.log(response);
        });
    },
    createEntity() {
      let entityData = {
        title: this.entity.title,
        date: this.entity.date,
        photo: this.entity.photo,
        text: this.entity.text,
        detail: this.entity.detail,
        container: this.entity.container
      };
      axios.post(`${server.baseURL}/admin/management/createentity`, entityData).then(response => {
        if (this.arrAttaches.length > 0) {
          this.createAttaches(response.data.id);
        }
        router.push({ name: "Management" });
      });
    },
    navigate() {
      router.push({ name: 'Management' })
   }
  },
  components: {
    VueEditor,
  },
};
</script>