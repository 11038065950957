<template>
  <div class="container col-md-4 ">
    <div class="card align-self-center p-2">
   <h3 class="mb-4"> Редактирование роли </h3>
   <form id="edit-user-form" @submit.prevent="editRole">
    <div class="form-group row mb-2">
      <label class="col-sm-4 col-form-label">Роль</label>
      <div class="col-sm-8">
        <input type="text" class="form-control" pattern=".{4,}" required title="Минимум 4 символа"
        v-model="role.role"
        name="role"
        >
      </div>
    </div>
    <div class="btn-toolbar justify-content-between">
      <button class="btn btn-outline-success float-left" v-on:click="navigate()">
        Вернуться назад
      </button>
      <button class="btn btn-success float-right" type="submit">
        Редактировать
      </button>
    </div>
   </form>
  </div>
 </div>
</template>

<script>
import { server } from "@/utils/helper";
import axios from "axios";
import router from "@/router";

export default {
  data() {
    return {
      id: 0,
      role: {},
    };
  },
  created() {
    this.id = this.$route.params.id;
    this.getRole();

  },
   methods: {
    editRole() {
    let roleData = {
     role: this.role.role,
    }
    axios
     .put(`${server.baseURL}/admin/roles/edit/${this.id}`, roleData)
     .then(() => {
      router.push({ name: 'Roles' })
     })
   },
   getRole() {
    axios
     .get(`${server.baseURL}/admin/roles/role/${this.id}`)
     .then(data => ( this.role = data.data ))
   },
   navigate() {
    router.push({ name: 'Roles' })
   }
  }
};
</script>
