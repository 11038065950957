<template>
    <div class="container-fluid px-4 my-0 border-top pt-3">
      <div class="row text-center">
        <div class="social-links">

          <a href="mailto:elprof@inbox.ru" target="_blank" title="Напишите нам">
            <i class="bi bi-envelope-fill"></i>
          </a>
          <a href="https://t.me/elprof75" target="_blank" title="Наша группа Телеграмм">
            <i class="bi bi-telegram"></i>
          </a>
          <a href="https://vk.com/club211713611" target="_blank" title="Наша группа ВК">
            <i class="bi bi-bootstrap-fill"></i>
          </a>
        </div>
        <p class="text-muted m-0">&copy; 2022 ЗабКО "ВЭП", Все права защищены</p>
      </div>
    </div>
</template>

<style lang="scss">
.social-links a {
  color: grey;
  padding-right: 12px;
  display: inline-block;
  line-height: 1px;
  transition: 0.3s;
}

.social-links a:hover {
  color: rgb(82,116,161);
}
</style>

<script>
export default {
  name: "FooterBox",
}
</script>