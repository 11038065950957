<template>
  <div class="card border-light mb-2">
    <div class="row g-1">
      <div class="col-md-4">
        <img :src="serverAdr+'/files/'+entity.photo" crossorigin="anonymous" class="img-fluid rounded-start">
      </div>
      <div class="col-md-8 align-items-start">
        <div class="card-body p-1">
          <h6 class="card-title"><strong>{{entity.title}}</strong></h6>
          <p v-html="entity.text"></p>
          <div class="flex-fill" v-if="entity.attaches.length > 0">
            <Attach v-for="attach in entity.attaches" :key="attach.id" :attach="attach"></Attach>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style>

</style>

<script>
import Attach from '@/components/entities/Attach.vue';
import { server } from "@/utils/helper";

export default {
  data() {
    return {
      serverAdr: server.baseURL,
    };
  },
  props: {
    entity: {
      type: Object,
      required: false
    }
  },
  components: {
    Attach,
  },
}
</script>