<template>
  <Navadmin></Navadmin>
  <vue-headful
    title="Забайкальская краевая организация &quot;Всероссийского Электропрофсоюза&quot; : Управление"
    description='Управление'
  />
  <div class="d-flex justify-content-between">
    <div class="d-flex m-0">
      <div class="m-1">
        <input id="search" type="search" class="form-control" list="searchOptions" placeholder="Поиск трекера" @change="eventOneTracker($event)">
        <datalist id="searchOptions">
          <option v-for="tracker in trackers" :value="tracker.path" :key="tracker.id">
            {{tracker.page}}
          </option>
        </datalist>
      </div>
    </div>
    <div class="d-flex m-0">
      <router-link :to="{ name: 'CreateContainer'}">
        <button type="button" class="btn btn-success m-1">
          <i class="bi bi-box"></i>
        </button>
      </router-link>
      <div id="entityForce" class="btn-group" role="group">
        <button id="btnGroupDrop1" type="button" class="btn btn-success m-1 dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
          <i class="bi bi-card-heading"></i>
        </button>
        <ul class="dropdown-menu bg-success text-white w-50" aria-labelledby="btnGroupDrop1">
          <li><router-link class="scrollto nav-link"
            :to="{ name: 'CreateEntity', params: { type: 'blog' }}">Блог-новости</router-link></li>
          <li><router-link class="scrollto nav-link"
            :to="{ name: 'CreateEntity', params: { type: 'extrablog' }}">Бол. карточка</router-link></li>
          <li><router-link class="scrollto nav-link"
          :to="{ name: 'CreateEntity', params: { type: 'card' }}">Об. карточка</router-link></li>
          <li><router-link class="scrollto nav-link"
            :to="{ name: 'CreateEntity', params: { type: 'miniblog' }}">Мал. карточка</router-link></li>
          <li><router-link class="scrollto nav-link"
            :to="{ name: 'CreateEntity', params: { type: 'tab' }}">Боковое меню</router-link></li>
          <li><router-link class="scrollto nav-link"
            :to="{ name: 'CreateEntity', params: { type: 'accordion' }}">Аккордеон</router-link></li>
          <li><router-link class="scrollto nav-link"
            :to="{ name: 'CreateEntity', params: { type: 'text' }}">Текст</router-link></li>
          <li><router-link class="scrollto nav-link"
            :to="{ name: 'CreateEntity', params: { type: 'gallery' }}">Галерея</router-link></li>
          <li><router-link class="scrollto nav-link"
            :to="{ name: 'CreateEntity', params: { type: 'files' }}">Файлы</router-link></li>
        </ul>
      </div>
      <router-link :to="{ name: 'CreateAttach'}">
        <button type="button" class="btn btn-success m-1">
          <i class="bi bi-paperclip"></i>
        </button>
      </router-link>
    </div>
  </div>
  <div id="managePage" class="row justify-content-center m-0">

    <div class="row mb-2" v-for="track in selecttrackers" :key="track.id">
      <!--container-->
      <div v-if="track.containers==''">
        Контейнеры не найдены
      </div>
      <div class="row gx-2 gy-2" v-for="container in track.containers" :key="container.id">
        <div class="col-md-4 card text-white bg-success p-1">
          <div class="card-body p-1 d-flex justify-content-between">
            <p class="m-0">#{{ container.id }}</p>
            <p class="m-0">
                {{ container.group }} - {{ container.type }} [{{ container.position }}]
            </p>
            <div>
              <router-link :to="{ name: 'EditContainer', params: { id: container.id } }">
                <i class="bi bi-pencil"/>
              </router-link>
              <a class="apointer ms-3" v-on:click="deleteContainer(container.id, $event)">
                <i class="bi bi-trash" />
              </a>
            </div>
          </div>
          <p class="text-start m-0">{{ container.header }}</p>
        </div>
        <!--begin entity-->
        <div class="row gx-2 gy-2">
          <div class="col-md-4" v-for="entity in container.entities" :key="entity.id">
            <div class="card text-white bg-secondary p-1">
              <div class="card-header d-flex justify-content-between">
                <p class="m-0">#{{ entity.id }}</p>
                <p v-if="entity.date" class="m-0">{{new Date(entity.date).toLocaleDateString()}}</p>
                <div>
                  <router-link :to="{ name: 'EditEntity', params: { id: entity.id, type: container.type} }">
                    <i class="bi bi-pencil"/>
                  </router-link>
                  <a class="apointer ms-3" v-on:click="deleteEntity(entity.id, $event)">
                    <i class="bi bi-trash" />
                  </a>
                </div>
              </div>
              <div class="card-body p-1">
                <p class="m-0">{{ entity.title }}</p>
                <!--begin attach-->
                <div v-for="attach in entity.attaches" :key="attach.id">
                  <div id="attachBlock" class="card text-black bg-warning p-1 m-1">
                    <div class="card-header d-flex justify-content-between">
                      <p class="m-0">#{{ attach.id }}</p>
                      <div>
                        <router-link :to="{ name: 'EditAttach', params: { id: attach.id } }">
                          <i class="bi bi-pencil"/>
                        </router-link>
                        <a class="apointer ms-3" v-on:click="deleteAttach(attach.id, $event)">
                          <i class="bi bi-trash" />
                        </a>
                      </div>
                    </div>
                    <div class="card-body p-1">
                      <p class="m-0">{{ attach.title }}</p>
                      <a :href="attach.path" target="_blank" class="btn btn-light">
                        <p class="m-0">{{ attach.type }}</p>
                      </a>
                    </div>
                  </div>
                </div>
                <!--end attach-->
              </div>
            </div>
            </div>
          </div>
          <!--end entity-->
        </div>
        <!--end container-->
      </div>
    </div>
  </template>

  <style>
  #managePage i,
  #managePage a {
    color: white;
  }

  #search::-webkit-search-cancel-button,
#search::-webkit-search-results-button{
    position:relative;
}

#attachBlock i,
#attachBlock a {
  color: black;
}

#entityForce .dropdown-menu {
  max-width: 1rem;
  left: 0;
  margin: 0;
  padding: 0;
  z-index: 99;
  background: #fff;
  transition: 0.3s;
}

#entityForce .dropdown-menu li {
  padding: 2px;
}

#entityForce .dropdown-menu a:hover,
#entityForce .dropdown-menu .active:hover,
#entityForce .dropdown-menu li:hover>a {
  background: yellowgreen;
}

.apointer:hover {
  cursor: pointer;
}
</style>

<script>
import { server } from "@/utils/helper";
import axios from "axios";
import Navadmin from '@/components/Navadmin.vue'

export default {
  data() {
    return {
      serverAdr: server.baseURL,
      trackers: [],
      selecttrackers: [],
    };
  },
  created() {
    this.fetchTrackers();
    this.homeTracker();
  },
  methods: {
    async fetchTrackers() {
      await axios
        .get(`${server.baseURL}/admin/management`)
        .then(data => (
          this.trackers = data.data
          ));
    },
    async homeTracker() {
      await axios
        .get(`${server.baseURL}/admin/management?tracker=home`)
        .then(data => (
          this.selecttrackers = data.data
          ));
    },
    async eventOneTracker(event) {
      this.selecttrackers = [];
      if (event.target.value) {
        await axios
        .get(`${server.baseURL}/admin/management?tracker=${event.target.value}`)
        .then(data => (
          this.selecttrackers = data.data
          ));
      }
    },
    async deleteContainer(id,event) {
      await axios
        .delete(`${server.baseURL}/admin/management/deletecontainer/${id}`)
        .then(() => {
          event.target.parentElement.parentElement.parentElement.style.visibility = 'hidden';
        });
    },
    async deleteEntity(id,event) {
      await axios
        .delete(`${server.baseURL}/admin/management/deleteentity/${id}`)
        .then(() => {
          event.target.parentElement.parentElement.parentElement.parentElement.style.visibility = 'hidden';
        });
    },
    async deleteAttach(id,event) {
      await axios
        .delete(`${server.baseURL}/admin/management/deleteattach/${id}`)
        .then(() => {
          event.target.parentElement.parentElement.parentElement.parentElement.style.visibility = 'hidden';
        });
    },
  },
  components: {
    Navadmin,
  },
};
</script>
