import { createApp } from "vue";
import vueHeadful from 'vue-headful';
import App from "@/App.vue";
import router from "@/router";
import store from '@/utils/store';
import axios from "axios";

const limit = 24 * 3600 * 1000;
const time = localStorage.getItem('time-token');
const token = localStorage.getItem('user-token');
if (+new Date() - time > limit) {
  localStorage.clear();
}
else if (token) {
  axios.defaults.headers.common['Authorization'] = token;
}

const app = createApp(App);

app.component('vue-headful', vueHeadful);

app.use(store);
app.use(router);

app.mount("#app");