<template>
  <div class="wrapper">
    <!-- <div id="topImg" v-if="$route.name=='home'">
      <Hero></Hero>
    </div> -->
    <Navbar></Navbar>
    <div id="app" class="container-fluid px-4">
        <router-view :key="$route.path"></router-view>
    </div>
  </div>
  <footer>
    <FooterBox></FooterBox>
  </footer>
</template>

<style lang="scss">
  #topImg {
    height: 100%;
  }

@media (min-width: 681px) {
  .wrapper{
    min-height: 88vh;
    position: relative;
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
  }
}

@media (max-width: 680px) {
  .wrapper{
    min-height: 88vh;
    position: relative;
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
  }
}

footer {
  background-color: #fff;
}
</style>

<script>
// import Hero from '@/components/Hero.vue'
import FooterBox from '@/components/Footer.vue'
import Navbar from '@/components/Navbar.vue'
import axios from "axios";

export default {
  components: {
    // Hero,
    Navbar,
    FooterBox,
  },
  created: function () {
    axios.interceptors.response.use(undefined, function (err) {
      // eslint-disable-next-line
    return new Promise(function (resolve, reject) {
      if (err.status === 401 && err.config && !err.config.__isRetryRequest) {
        this.$store.dispatch('AUTH_LOGOUT')
      }
      throw err;
    });
  });
}
}
</script>