<template>
  <div class="accordion accordion-flush" id="accordionFlushExample">
    <div class="accordion-item" v-for="entity in entities" :key="entity.id">
      <h6 class="accordion-header" :id="'heading'+entity.id">
        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" :data-bs-target="'#acc'+entity.id" aria-expanded="false" :aria-controls="'acc'+entity.id">
          {{entity.title}}
        </button>
      </h6>
      <div :id="'acc'+entity.id" class="accordion-collapse collapse" :aria-labelledby="'heading'+entity.id" data-bs-parent="#accordionFlushExample">
        <div class="accordion-body">
          <p v-html="entity.text"></p>
          <div class="d-flex justify-content-start align-items-center" v-if="entity.attaches.length > 0">
            <Attach v-for="attach in entity.attaches" :key="attach.id" :attach="attach" :title=true
            class="ms-2 align-items-center"></Attach>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
.accordion {
  align-items: stretch;
}

.accordion-body {
  text-align: justify;
}

.accordion-body .ql-align-justify{
  text-indent: 2rem;
}

.accordion-body ul .ql-align-justify {
  text-indent: 0em;
}

.accordion-body ol .ql-align-justify {
  text-indent: 0em;
}
</style>

<script>
import Attach from '@/components/entities/Attach.vue'

export default {
  props: {
    entities: {
      type: Array,
      required: false
    }
  },
  components: {
    Attach,
  }
}
</script>