<template>
  <div id="main">
  <div v-if="!isLoaded" class="text-center">
    <div class="spinner-border text-primary" role="status">
      <span class="visually-hidden">Загрузка...</span>
    </div>
  </div>
  <div v-if="error" class="d-flex align-items-center justify-content-center">
    <div class="m-2">
      <div class=" col-md-12">
          <i class="bi bi-code-slash" style="font-size: 3rem; color: rgb(82,116,161);"></i>
      </div>
      <div class=" col-md-12 mt-3">
          <p class="fs-3"> <span class="text-danger">Упс!</span> Нет связи с сервером</p>
          <p class="lead">
              Обновите страницу позднее...
          </p>
          <p class="text-sm">
              Ошибка: {{error}}
          </p>
      </div>
    </div>
  </div>
  <div v-for="container in containers" :key="container.id">
    <vue-headful
            :title="'Забайкальская краевая организация &quot;Всероссийского Электропрофсоюза&quot; : '+ container.header"
            :description='container.header'
        />
    <div v-if="container.type=='blog' && container.entities.length > 0">
      <div class="row">
        <div class="col-md-9">
          <Blog :entities="container.entities" :header="container.header"></Blog>
        </div>
        <div class="col-md-3">
          <Vidgets></Vidgets>
        </div>
      </div>
    </div>
    <div class="mb-3" v-else-if="container.type=='extrablog' && container.entities.length > 0">
      <h5>{{container.header}}</h5>
      <Extrablog v-for="entity in container.entities" :key="entity.id" :entity="entity"></Extrablog>
    </div>
    <div class="mb-3" v-else-if="container.type=='card' && container.entities.length > 0">
      <h5>{{container.header}}</h5>
      <div class="row row-cols-sm-3 row-cols-1">
        <Card v-for="entity in container.entities" :key="entity.id" :entity="entity"></Card>
      </div>
    </div>
    <div class="mb-3" v-else-if="container.type=='miniblog' && container.entities.length > 0">
      <h5>{{container.header}}</h5>
      <Miniblog v-for="entity in container.entities" :key="entity.id" :entity="entity"></Miniblog>
    </div>
    <div class="mb-3" v-else-if="container.type=='tab' && container.entities.length > 0">
      <Tab :entities="container.entities"></Tab>
    </div>
    <div class="mb-3" v-else-if="container.type=='accordion' && container.entities.length > 0">
      <h5>{{container.header}}</h5>
      <Accordion :entities="container.entities"></Accordion>
    </div>
    <div class="mb-3" v-else-if="container.type=='text' && container.entities.length > 0">
      <h5>{{container.header}}</h5>
      <TextBox v-for="entity in container.entities" :key="entity.id" :entity="entity"></TextBox>
    </div>
    <div class="mb-3" v-else-if="container.type=='gallery' && container.entities.length > 0">
      <h5>{{container.header}}</h5>
      <div class="row gx-2 gy-3 mt-1">
        <Gallery v-for="entity in container.entities" :key="entity.id" :entity="entity"></Gallery>
      </div>
    </div>
    <div class="mb-3" v-else-if="container.type=='files' && container.entities.length > 0">
      <h5>{{container.header}}</h5>
      <div v-if="container.group=='foto'" class="d-flex align-items-center justify-content-center flex-wrap">
        <Files v-for="entity in container.entities" :key="entity.id" :entity="entity" :zip=true></Files>
      </div>
      <div v-else>
        <Files v-for="entity in container.entities" :key="entity.id" :entity="entity"></Files>
      </div>
    </div>
  </div>
  </div>
</template>

<style lang="scss">
blockquote {
    border-left: 6px solid #BFE2FF;
    font-size: 16px;
    font-style: italic;
    margin: 16px;
    padding: 16px 24px;
    position: relative;
}
blockquote:before {
    content: "";
    position: absolute;
    top: 50%;
    left: -6px;
    height: 40px;
    background-color: #fff;
    width: 6px;
    margin-top: -1em;
}
blockquote:after {
    content: '\201c';
    position: absolute;
    top: 50%;
    left: -55px;
    color: #337AB7;
    font-size: 50px;
    font-family: Times, sans-serif;
    font-weight: bold;
    line-height: 30px;
}

.ql-align-justify {
  text-align: justify;
  margin: 0em;
}

.ql-align-center {
  text-align: center;
  margin: 0em;
}

.ql-align-left {
  text-align: left;
  margin: 0em;
}

.ql-align-right {
  text-align: right;
  margin: 0em;
}

h5 {
  text-align: left;
  font-weight: 550;
  font-size: medium;
}

.card-img-top {
    width: 100%;
    height: 15vw;
    object-fit: cover;
}

.card-text {
   overflow: hidden;
   text-overflow: ellipsis;
   display: -webkit-box;
   -webkit-line-clamp: 2; /* number of lines to show */
           line-clamp: 2;
   -webkit-box-orient: vertical;
}
</style>

<script>
import { server } from "@/utils/helper";
import axios from "axios";
import TextBox from '@/components/entities/Text.vue'
import Gallery from '@/components/entities/Gallery.vue'
import Files from '@/components/entities/Files.vue'
import Accordion from '@/components/entities/Accordion.vue'
import Miniblog from '@/components/entities/Miniblog.vue'
import Card from '@/components/entities/Card.vue'
import Extrablog from '@/components/entities/Extrablog.vue'
import Blog from '@/components/entities/Blog.vue'
import Vidgets from '@/components/entities/Vidgets.vue'
import Tab from '@/components/entities/Tab.vue'

export default {
  el: '#main',
  metaInfo() {
    return {
      title: "test meta data with vue",
      metaInfo: [
        {
          vmid: "description",
          name: "description",
          content:
            "hello world, this is an example of adding a description with vueMeta",
        },
      ],
    };
  },
  data() {
    return {
      containers: [],
      isLoaded: false,
      error: ''
    };
  },
  beforeMount() {
    this.isLoaded = false;
    this.error = '';
  },
  created() {
    this.fetchContainers();
  },
  beforeUpdate() {
    this.isLoaded = true;
  },
  methods: {
    async fetchContainers() {
      if (this.$route.path=='/') {
        await axios
        .get(`${server.baseURL}/home`)
        .then(data => (this.containers = data.data))
        .catch(error => (this.error = error));
      }
      else {
        await axios
        .get(`${server.baseURL}${this.$route.path}`)
        .then(data => (this.containers = data.data))
        .catch(error => (this.error = error));
      }
    },
  },
  components: {
    TextBox,
    Gallery,
    Files,
    Accordion,
    Miniblog,
    Card,
    Extrablog,
    Blog,
    Vidgets,
    Tab,
  },
};
</script>