<template>
  <div v-if="errorBlog" class="d-flex align-items-center justify-content-center">
    <div class="m-2">
      <div class=" col-md-12">
          <i class="bi bi-code-slash" style="font-size: 3rem; color: rgb(82,116,161);"></i>
      </div>
      <div class=" col-md-12 mt-3">
          <p class="fs-3"> <span class="text-danger">Упс!</span> Нет связи с сервером</p>
          <p class="lead">
              Обновите страницу позднее...
          </p>
          <p class="text-sm">
              Ошибка: {{error}}
          </p>
      </div>
    </div>
  </div>
  <div class="row gx-4">
    <div class="col-md-9">
      <a href="/">
        <button class="btn d-flex align-items-start btn-detail mb-3">
          Вернуться назад
        </button>
      </a>
      <h6 class="card-title text-center mb-3"><strong>{{blog.title}}</strong></h6>
      <img :src="serverAdr+'/files/'+blog.photo" crossorigin="anonymous" class="img rounded-start">
      <p class="card-text detailBLOG text-muted text-end mb-1">{{new Date(blog.date).toLocaleDateString()}}</p>
      <p class="detailBLOGID" v-html="blog.detail"></p>
      <div class="justify-content-start align-items-center col-md-4 mb-2" v-if="blog.attaches.length > 0">
        <p class="text-start m-0">Вложения:</p>
        <Attach v-for="attach in blog.attaches" :key="attach.id" :attach="attach"></Attach>
      </div>
      <a href="/">
        <button class="btn d-flex align-items-start btn-detail mb-3">
          Вернуться назад
        </button>
      </a>
    </div>
    <div class="col-md-3">
        <Vidgets></Vidgets>
    </div>
  </div>
</template>

<style>
.detailBLOGID .ql-align-justify{
  text-indent: 2rem;
}

.detailBLOGID ul .ql-align-justify {
  text-indent: 0em;
}

.detailBLOGID ol .ql-align-justify {
  text-indent: 0em;
}
</style>

<script>
import { server } from "@/utils/helper";
import axios from "axios";
import Attach from '@/components/entities/Attach.vue'
import Vidgets from '@/components/entities/Vidgets.vue'

export default {
  data() {
    return {
      id: 0,
      blog: {},
      serverAdr: server.baseURL,
      errorBlog: ''
    };
  },
  beforeMount() {
    this.errorBlog = '';
  },
  created() {
    this.id = this.$route.params.id;
    this.getBlog();
  },
  components: {
    Attach,
    Vidgets,
  },
   methods: {
   async getBlog() {
    await axios
     .get(`${server.baseURL}/home/news/${this.id}`)
     .then(data => ( this.blog = data.data ))
     .catch(error => (this.errorBlog = error));
   }
  }
};
</script>