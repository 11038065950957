<template>
  <div class="container col-md-4 ">
    <div class="card align-self-center p-2">
      <button class="btn btn-outline-success float-left mb-2" v-on:click="navigate()">
        Вернуться назад
      </button>
      <h3 class="mb-4">Создание вложения</h3>
      <form id="create-user-form" @submit.prevent="createAttach">
        <div class="form-group row mb-2">
          <label class="col-sm-4 col-form-label">Заголовок</label>
          <div class="col-sm-8">
            <input type="text" class="form-control"
            v-model="attach.title" name="title" placeholder="Введите заголовок">
          </div>
        </div>
        <div class="form-group row mb-2">
          <label class="col-sm-4 col-form-label">Тип</label>
          <div class="col-sm-8">
            <select class="form-select" v-model="attach.type" name="type" required>
              <option value="doc">doc</option>
              <option value="img">img</option>
              <option value="xls">xls</option>
              <option value="pdf">pdf</option>
              <option value="zip">zip</option>
              <option value="link">link</option>
              <option value="ppt">ppt</option>
            </select>
          </div>
        </div>
        <div class="form-group row mb-2">
          <label class="col-sm-4 col-form-label">Путь</label>
          <div class="col-sm-8">
            <input type="text" class="form-control" required
            v-model="attach.path" name="path" placeholder="Введите путь">
          </div>
        </div>
        <div class="form-group row mb-4">
          <label for="staticEmail" class="col-sm-4 col-form-label">Сущность</label>
          <div class="col-sm-8">
            <select class="form-select" v-model="attach.entity" name="entity" required>
              <option v-for="entity in entities" :value="entity.id" :key="entity.id">
                {{ entity.id }}
              </option>
            </select>
          </div>
        </div>
        <div class="btn-toolbar justify-content-between">
          <button class="btn btn-outline-success float-left" v-on:click="navigate()">
            Вернуться назад
          </button>
          <button class="btn btn-success float-right" type="submit">
            Создать
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { server } from "@/utils/helper";
import router from "@/router";

export default {
  data() {
    return {
      entities: [],
      attach: {},
    };
  },
  created() {
    this.fetchEntities();
  },
  methods: {
    fetchEntities() {
      axios
        .get(`${server.baseURL}/admin/management/entities`)
        .then(data => (this.entities = data.data));
    },
    createAttach() {
      let attachData = {
        title: this.attach.title,
        type: this.attach.type,
        path: this.attach.path,
        entity: this.attach.entity,
      };
      this.__submitToServer(attachData);
    },
    __submitToServer(data) {
      axios.post(`${server.baseURL}/admin/management/createattach`, data).then(() => {
        router.push({ name: "Management" });
      });
    },
    navigate() {
      router.push({ name: 'Management' })
   }
  },
};
</script>