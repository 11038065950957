<template>
  <div class="row justify-content-center">
    <div class="nav nav-pills flex-column col-md-3" id="nav-tab" role="tablist" aria-orientation="vertical">
      <button v-for="entity in entities" :key="entity.id" class="nav-link" :id="'nav-tab'+entity.id" data-bs-toggle="tab" :data-bs-target="'#nav'+entity.id" type="button" role="tab" aria-controls="'nav'+entity.id" aria-selected="false">
        {{entity.title}}
      </button>
    </div>
    <div class="tab-content align-self-stretch col-md-9 p-2" id="nav-tabContent">
      <div v-for="entity in entities" :key="entity.id" class="tab-pane fade" :id="'nav'+entity.id" role="tabpanel" :aria-labelledby="'nav-tab'+entity.id">
        <div class="tabTEXT" v-html="entity.text"></div>
        <div class="row row-cols-sm-4 row-cols-1 g-1 align-items-center mb-3" v-if="entity.attaches.length > 0">
          <Attach v-for="attach in entity.attaches" :key="attach.id" :attach="attach"></Attach>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
#nav-tab button {
  color: rgb(82,116,161);
  font-weight: 600;
}

#nav-tab .active {
  color: #e3f2fd;
  font-weight: 600;
  background: rgb(82,116,161);
}

.tab-content img {
   max-width: 100%;
   height: auto;
}

.tabTEXT .ql-align-justify{
  text-indent: 2rem;
}

.tabTEXT ul .ql-align-justify {
  text-indent: 0em;
}

.tabTEXT ol .ql-align-justify {
  text-indent: 0em;
}
</style>

<script>
import Attach from '@/components/entities/Attach.vue'

export default {
  props: {
    entities: {
      type: Array,
      required: false
    }
  },
  components: {
    Attach,
  },
}
</script>