<template>
  <div class="container col-md-4 ">
    <div class="card align-self-center p-2">
      <button class="btn btn-outline-success float-left mb-2" v-on:click="navigate()">
        Вернуться назад
      </button>
      <h3 class="mb-4">Создание контейнера</h3>
      <form id="create-user-form" @submit.prevent="createContainer">
        <div class="form-group row mb-2">
          <label class="col-sm-4 col-form-label">Заголовок</label>
          <div class="col-sm-8">
            <input type="text" class="form-control"
            v-model="container.header" name="header" placeholder="Введите заголовок">
          </div>
        </div>
        <div class="form-group row mb-2">
          <label class="col-sm-4 col-form-label">Группа</label>
          <div class="col-sm-8">
            <input type="text" class="form-control" required
            v-model="container.group" name="group" placeholder="Введите группу">
          </div>
        </div>
        <div class="form-group row mb-2">
          <label class="col-sm-4 col-form-label">Тип</label>
          <div class="col-sm-8">
            <select class="form-select" v-model="container.type" name="type" required>
              <option value="blog">blog</option>
              <option value="extrablog">extrablog</option>
              <option value="card">card</option>
              <option value="miniblog">miniblog</option>
              <option value="tab">tab</option>
              <option value="accordion">accordion</option>
              <option value="text">text</option>
              <option value="gallery">gallery</option>
              <option value="files">files</option>
            </select>
          </div>
        </div>
        <div class="form-group row mb-2">
          <label class="col-sm-4 col-form-label">Позиция</label>
          <div class="col-sm-8">
            <input type="number" class="form-control" required
            v-model="container.position" name="position" placeholder="Введите позицию">
          </div>
        </div>
        <div class="form-group row mb-4">
          <label for="staticEmail" class="col-sm-4 col-form-label">Трэкер</label>
          <div class="col-sm-8">
            <select class="form-select" v-model="container.tracker" name="tracker" required>
              <option v-for="tracker in trackers" :value="tracker.path" :key="tracker.id">
                {{ tracker.path }}
              </option>
            </select>
          </div>
        </div>
        <div class="btn-toolbar justify-content-between">
          <button class="btn btn-outline-success float-left" v-on:click="navigate()">
            Вернуться назад
          </button>
          <button class="btn btn-success float-right" type="submit">
            Создать
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { server } from "@/utils/helper";
import router from "@/router";

export default {
  data() {
    return {
      trackers: [],
      container: {},
    };
  },
  created() {
    this.fetchTrackers();
  },
  methods: {
    fetchTrackers() {
      axios
        .get(`${server.baseURL}/admin/management`)
        .then(data => (this.trackers = data.data));
    },
    createContainer() {
      let containerData = {
        header: this.container.header,
        group: this.container.group,
        type: this.container.type,
        position: this.container.position,
        tracker: this.container.tracker,
      };
      this.__submitToServer(containerData);
    },
    __submitToServer(data) {
      axios.post(`${server.baseURL}/admin/management/createcontainer`, data).then(() => {
        router.push({ name: "Management" });
      });
    },
    navigate() {
      router.push({ name: 'Management' })
   }
  },
};
</script>