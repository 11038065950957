<template>
  <div class="card border-light mb-2">
    <div class="p-2">
      <img :src="serverAdr+'/files/'+entity.photo" crossorigin="anonymous" class="img-extra img-fluid rounded-start">
      <!-- <div class="d-flex justify-content-between mb-0"> -->
        <h6 class="card-title text-start"><strong>{{entity.title}}</strong></h6>
        <p class="text-end m-0">{{new Date(entity.date).toLocaleDateString()}}</p>
      <!-- </div> -->
      <p v-html="entity.text"></p>
      <p v-html="entity.detail"></p>
      <div class="flex-fill p-2 m-2" v-if="entity.attaches.length > 0">
        <Attach v-for="attach in entity.attaches" :key="attach.id" :attach="attach"></Attach>
      </div>
    </div>
  </div>
</template>

<style>
.img-extra {
  float:left;
  margin-right: 1REM;
}
</style>

<script>
import Attach from '@/components/entities/Attach.vue';
import { server } from "@/utils/helper";

export default {
  data() {
    return {
      serverAdr: server.baseURL,
    };
  },
  props: {
    entity: {
      type: Object,
      required: false
    }
  },
  components: {
    Attach,
  }
}
</script>