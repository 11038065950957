<template>
  <nav id="navAdmin" class="nav nav-pills flex-column flex-sm-row mb-3">
    <router-link :to="{ name: 'Roles'}" class="flex-sm-fill text-sm-center nav-link me-1">
      Роли
    </router-link>
    <router-link :to="{ name: 'Users'}" class="flex-sm-fill text-sm-center nav-link me-1">
      Пользователи
    </router-link>
    <router-link :to="{ name: 'Management'}" class="flex-sm-fill text-sm-center nav-link me-1">
      Управление
    </router-link>
  </nav>
</template>

<style>
#navAdmin a {
  text-align: center;
  color: rgb(82,116,161);
  background-color: #e3f2fd;
}

#navAdmin a:hover {
  background: #e3f2fd;
}

#navAdmin a.active,
#navAdmin a.router-link-exact-active {
  color: #e3f2fd;
  font-weight: 600;
  background: rgb(82,116,161);
}
</style>

<script>
export default {
};
</script>