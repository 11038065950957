<template>
  <Navadmin></Navadmin>
  <div class="row justify-content-center m-0">
      <h3>
        Список пользователей
        <router-link
          :to="{ name: 'CreateUser'}"
          >
            <button type="button" class="btn btn-sm btn-outline-success">
              +
            </button>
        </router-link>
      </h3>
  </div>
  <div v-if="users.length === 0">
    <blockquote >пользователи отсутствуют</blockquote>
  </div>
  <div id="userBlock" class="row justify-content-center m-0">
    <div class="col-md-4" v-for="user in users" :key="user.id">
      <div class="card m-1">
        <div class="card-header d-flex justify-content-between">
          <p class="m-0">#{{ user.id }}</p>
          <p class="m-0">{{ user.user }} [{{ user.role.role }}]</p>
          <div>
            <router-link :to="{ name: 'EditUser', params: { id: user.id } }">
              <i class="bi bi-pencil"/>
            </router-link>
            <a class="apointer ms-3" v-on:click="deleteUser(user.id, $event)">
              <i class="bi bi-trash" />
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style>
#userBlock i,
#userBlock a {
  color: black;
}
</style>

<script>
import { server } from "@/utils/helper";
import axios from "axios";
import Navadmin from '@/components/Navadmin.vue'

export default {
  data() {
    return {
      users: [],
    };
  },
  created() {
    this.fetchUsers();
  },
  methods: {
    fetchUsers() {
      axios
        .get(`${server.baseURL}/admin/users`)
        .then(data => (
          //console.log(this.$route.path),
          this.users = data.data
          ));
    },
    deleteUser(id, event) {
      axios
        .delete(`${server.baseURL}/admin/users/delete/${id}`)
        .then(() => {
          event.target.parentElement.parentElement.parentElement.style.visibility = 'hidden';
        });
    },
  },
  components: {
    Navadmin,
  },
};
</script>
