<template>
  <div class="container col-md-4 ">
    <div class="card align-self-center p-2">
      <h3 class="mb-4">Создание пользователя</h3>
      <form id="create-user-form" @submit.prevent="createUser">
        <div class="form-group row mb-2">
          <label class="col-sm-4 col-form-label">Пользователь</label>
          <div class="col-sm-8">
            <input type="text" class="form-control" pattern=".{6,}" required title="Минимум 6 символов"
            v-model="user.user"
            name="user"
            placeholder="Введите имя пользователя"
            >
          </div>
        </div>
        <div class="form-group row mb-2">
          <label for="inputPassword" class="col-sm-4 col-form-label">Пароль</label>
          <div class="col-sm-8">
            <input type="password" class="form-control" pattern=".{6,}" required title="Минимум 6 символов"
            v-model="user.password"
            name="password"
            placeholder="Введите пароль"
            >
          </div>
        </div>
        <div class="form-group row mb-4">
          <label for="staticEmail" class="col-sm-4 col-form-label">Роль</label>
          <div class="col-sm-8">
            <select class="form-select" v-model="user.role" name="role" required>
                <option v-for="r in roles" :value="r.role" :key="r.id">
                    {{ r.role }}
                </option>
            </select>
          </div>
        </div>
        <div class="btn-toolbar justify-content-between">
          <button class="btn btn-outline-success float-left" v-on:click="navigate()">
            Вернуться назад
          </button>
          <button class="btn btn-success float-right" type="submit">
            Создать
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { server } from "@/utils/helper";
import router from "@/router";

export default {
  data() {
    return {
      roles: [],
      user: {},
    };
  },
  created() {
    this.fetchRoles();
  },
  methods: {
    fetchRoles() {
      axios
        .get(`${server.baseURL}/admin/roles`)
        .then(data => (this.roles = data.data));
    },
    createUser() {
      let usertData = {
        user: this.user.user,
        password: this.user.password,
        role: this.user.role
      };
      this.__submitToServer(usertData);
    },
    __submitToServer(data) {
      axios.post(`${server.baseURL}/admin/users/create`, data).then(() => {
        router.push({ name: "Users" });
      });
    },
    navigate() {
      router.push({ name: "Users" })
   }
  },
};
</script>
