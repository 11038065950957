<template>
  <div v-if="zip" class="m-2">
    <div v-if="entity.attaches.length > 0">
      <Attach v-for="attach in entity.attaches" :key="attach.id" :attach="attach" :zip=true></Attach>
    </div>
  </div>
  <div v-else>
    <h6><strong class="files-title">{{entity.title}}</strong></h6>
    <div class="row row-cols-sm-4 row-cols-1 g-1 align-items-center mb-3" v-if="entity.attaches.length > 0">
      <Attach v-for="attach in entity.attaches" :key="attach.id" :attach="attach"></Attach>
    </div>
  </div>
</template>

<style>
.files-title {
  font-size:large;
}
</style>

<script>
import Attach from '@/components/entities/Attach.vue'

export default {
  props: {
    entity: {
      type: Object,
      required: false
    },
    zip: {
      type: Boolean,
      required: false
    }
  },
  components: {
    Attach,
  },
}
</script>