<template>
  <div class="container col-md-4 ">
    <div class="card align-self-center p-2">
   <h3 class="mb-4"> Редактирование пользователя </h3>
   <form id="edit-user-form" @submit.prevent="editUser">
    <div class="form-group row mb-2">
      <label class="col-sm-4 col-form-label">Пользователь</label>
      <div class="col-sm-8">
        <input type="text" class="form-control" pattern=".{6,}" required title="Минимум 6 символов"
        v-model="user.user"
        name="user"
        >
      </div>
    </div>
    <div class="form-group row mb-2">
      <label for="inputPassword" class="col-sm-4 col-form-label">Пароль</label>
      <div class="col-sm-8">
        <input type="password" class="form-control" pattern=".{6,}" required title="Минимум 6 символов"
        v-model="user.password"
        name="password"
        >
      </div>
    </div>
    <div class="form-group row mb-4">
      <label for="staticEmail" class="col-sm-4 col-form-label">Роль</label>
      <div class="col-sm-4">
        <input type="text" class="form-control" v-model="user.role.role" disabled>
      </div>
      <div class="col-sm-4">
        <select class="form-control" v-model="user.role" name="role" requied>
            <option v-for="r in roles" :value="r.role" :key="r.id">
                {{ r.role }}
            </option>
        </select>
      </div>
    </div>
    <div class="btn-toolbar justify-content-between">
      <button class="btn btn-outline-success float-left" v-on:click="navigate()">
        Вернуться назад
      </button>
      <button class="btn btn-success float-right" type="submit">
        Редактировать
      </button>
    </div>
   </form>
  </div>
 </div>
</template>

<script>
import { server } from "@/utils/helper";
import axios from "axios";
import router from "@/router";

export default {
  data() {
    return {
      id: 0,
      roles: [],
      user: {},
    };
  },
  created() {
    this.id = this.$route.params.id;
    this.getRoles();
    this.getUser();

  },
   methods: {
    getRoles() {
      axios
        .get(`${server.baseURL}/admin/roles`)
        .then(data => (this.roles = data.data));
    },
   editUser() {
    let userData = {
     user: this.user.user,
     password: this.user.password,
     role: this.user.role,
    }
    axios
     .put(`${server.baseURL}/admin/users/edit/${this.id}`, userData)
     .then(() => {
      router.push({ name: 'Users' })
     })
   },
   getUser() {
    axios
     .get(`${server.baseURL}/admin/users/user/${this.id}`)
     .then(data => ( this.user = data.data ))
   },
   navigate() {
    router.push({ name: 'Users' })
   }
  }
};
</script>
