<template>
  <div class="container col-md-4 ">
    <div class="card align-self-center p-2">
      <button class="btn btn-outline-success float-left mb-2" v-on:click="navigate()">
        Вернуться назад
      </button>
   <h3 class="mb-4"> Редактирование контейнера</h3>
   <form id="edit-user-form" @submit.prevent="editContainer">
    <div class="form-group row mb-2">
      <label class="col-sm-4 col-form-label">Заголовок</label>
      <div class="col-sm-8">
        <input type="text" class="form-control"
        v-model="container.header" name="header">
      </div>
    </div>
    <div class="form-group row mb-2">
      <label class="col-sm-4 col-form-label">Группа</label>
      <div class="col-sm-8">
        <input type="text" class="form-control"
        v-model="container.group" name="group">
      </div>
    </div>
    <div class="form-group row mb-2">
      <label class="col-sm-4 col-form-label">Тип</label>
      <div class="col-sm-8">
        <input type="text" class="form-control"
        v-model="container.type" name="type">
      </div>
    </div>
    <div class="form-group row mb-2">
      <label class="col-sm-4 col-form-label">Позиция</label>
      <div class="col-sm-8">
        <input type="number" class="form-control"
        v-model="container.position" name="position">
      </div>
    </div>
    <div class="form-group row mb-4">
      <label for="staticEmail" class="col-sm-4 col-form-label">Трэкер</label>
      <div class="col-sm-4">
        <input type="text" class="form-control" :value="container.tracker.path" disabled>
      </div>
      <div class="col-sm-4">
        <select class="form-select" v-model="container.tracker" name="tracker" required>
          <option v-for="tracker in trackers" :value="tracker.path" :key="tracker.id">
            {{ tracker.path }}
          </option>
        </select>
      </div>
    </div>
    <div class="btn-toolbar justify-content-between">
      <button class="btn btn-outline-success float-left" v-on:click="navigate()">
        Вернуться назад
      </button>
      <button class="btn btn-success float-right" type="submit">
        Редактировать
      </button>
    </div>
   </form>
  </div>
 </div>
</template>

<script>
import { server } from "@/utils/helper";
import axios from "axios";
import router from "@/router";

export default {
  data() {
    return {
      id: 0,
      trackers: [],
      container: {},
    };
  },
  created() {
    this.id = this.$route.params.id;
    this.getTrackers();
    this.getContainer();

  },
   methods: {
    getTrackers() {
      axios
      .get(`${server.baseURL}/admin/management`)
        .then(data => (this.trackers = data.data));
    },
    editContainer() {
    let containerData = {
      header: this.container.header,
      group: this.container.group,
      type: this.container.type,
      position: this.container.position,
      tracker: this.container.tracker,
    };
    axios
     .put(`${server.baseURL}/admin/management/editcontainer/${this.id}`, containerData)
     .then(() => {
      router.go(-1)
     })
   },
   getContainer() {
    axios
     .get(`${server.baseURL}/admin/management/getcontainer/${this.id}`)
     .then(data => ( this.container = data.data ))
   },
   navigate() {
    router.push({ name: 'Management' })
   }
  }
};
</script>
