<template>
  <div id="allTop" class="sticky-top">
    <span id="topbar" class="d-block d-lg-block">
      <div class="d-flex justify-content-between align-items-center p-1">
        <div class="contact-info ms-1">
          <a href="https://yandex.ru/maps/-/CCURnJckDA" target="_blank" class="d-flex align-items-center">
            <i class="bi bi-geo-alt-fill me-1"></i>
            <span class="mobileHide">г.Чита, ул.Ленина, д.90, оф.37</span>
          </a>
        </div>
        <a href="/">
          <img src="@/assets/logo.png">
        </a>
        <div class="contact-info me-1">
          <a href="tel:+73022356957" class="d-flex align-items-center">
            <i class="bi bi-phone-fill phone-icon me-1"></i>
            <span class="mobileHide">+7 (3022) 35-69-57</span>
          </a>
        </div>
      </div>
    </span>
    <!--панель меню-->
    <div id="nav">
      <nav class="navbar navbar-expand-lg p-0">
        <div class="container-fluid">
          <button class="navbar-toggler float-sm-right m-1" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
              <span class="navbar-toggler-icon"></span>
          </button>
          <div class="collapse navbar-collapse justify-content-center align-items-center" id="navbarSupportedContent">
              <div class="navbar navbar-nav">
                <ul class="navbar-nav me-auto mb-2 mb-lg-0">
                  <li class="dropdown">
                    <a href="#"
                    :class="{ active: $route.path.includes('/about')}">О профсоюзе</a>
                    <ul>
                      <li><router-link to="/about/history" class="scrollto nav-link"  v-on:click="collapseToggle">История профсоюза</router-link></li>
                      <li><router-link to="/about/structure" class="scrollto nav-link" v-on:click="collapseToggle">Структура профсоюза</router-link></li>
                      <li><router-link to="/about/company" class="scrollto nav-link" v-on:click="collapseToggle">Руководство и аппарат</router-link></li>
                      <li><a href="https://cloud.mail.ru/public/TQFd/wJjQavgDY" target="_blank" class="scrollto nav-link">Уставные документы<i class="bi bi-box-arrow-up-right"></i></a></li>
                      <li><router-link to="/about/entry" class="scrollto nav-link" v-on:click="collapseToggle">Как вступить в профсоюз</router-link></li>
                      <li><router-link to="/about/gallery" class="scrollto nav-link" v-on:click="collapseToggle">Фото-архив</router-link></li>
                    </ul>
                  </li>
                  <li class="dropdown">
                    <a href="#" :class="{ active: $route.path.includes('/activity')}">Деятельность</a>
                    <ul>
                      <li><router-link to="/activity/information" class="scrollto nav-link" v-on:click="collapseToggle">Информационная работа</router-link></li>
                      <li><router-link to="/activity/organization" class="scrollto nav-link" v-on:click="collapseToggle">Организационная работа</router-link></li>
                      <li><router-link to="/activity/labor" class="scrollto nav-link" v-on:click="collapseToggle">Охрана труда<i class="bi bi-lock-fill" v-if="!isAuthenticated"></i></router-link></li>
                      <li><router-link to="/activity/legal" class="scrollto nav-link" v-on:click="collapseToggle">Правовая работа<i class="bi bi-lock-fill" v-if="!isAuthenticated"></i></router-link></li>
                      <li><router-link to="/activity/social" class="scrollto nav-link" v-on:click="collapseToggle">Социальное партнерство</router-link></li>
                      <li><router-link to="/activity/creative" class="scrollto nav-link" v-on:click="collapseToggle">Творческая работа</router-link></li>
                      <li><router-link to="/activity/financial" class="scrollto nav-link" v-on:click="collapseToggle">Финансовая работа<i class="bi bi-lock-fill" v-if="!isAuthenticated"></i></router-link></li>
                      <li><router-link to="/activity/law" class="scrollto nav-link" v-on:click="collapseToggle">Юридическая консультация</router-link></li>
                      <li><a href="https://pfr.gov.ru/branches/novosibirsk/info/~Gragdanam/1107" target="_blank" class="scrollto nav-link">Пенсионный вестник<i class="bi bi-box-arrow-up-right"></i></a></li>
                    </ul>
                  </li>
                  <li class="dropdown">
                    <a href="#" :class="{ active: $route.path.includes('/documents')}">Документы</a>
                    <ul>
                      <li><a href="https://www.elprof.ru/doc/" target="_blank" class="scrollto nav-link">Документы ВЭП<i class="bi bi-box-arrow-up-right"></i></a></li>
                      <li><router-link to="/documents/docsZABCO" class="scrollto nav-link" v-on:click="collapseToggle">Документы ЗабКО ВЭП<i class="bi bi-lock-fill" v-if="!isAuthenticated"></i></router-link></li>
                    </ul>
                  </li>
                  <li><router-link to="/ppo" class="scrollto nav-link" v-on:click="collapseToggle">ППО</router-link></li>
                  <li class="dropdown">
                    <a href="#" :class="{ active: $route.path.includes('/young')}">Молодежь</a>
                    <ul>
                      <li><a href="https://cloud.mail.ru/public/n97n/m49q4RuLq" target="_blank" class="scrollto nav-link">Положение о молодежном совете<i class="bi bi-box-arrow-up-right"></i></a></li>
                      <li><a href="https://cloud.mail.ru/public/Yenr/4rpKwRDW6" target="_blank" class="scrollto nav-link">О концепции молодежной политики<i class="bi bi-box-arrow-up-right"></i></a></li>
                      <li><a href="https://vk.com/molsov_prof" target="_blank" class="scrollto nav-link">Молодежный совет<i class="bi bi-box-arrow-up-right"></i></a></li>
                      <li><a href="https://stratrezerv.fnpr.ru/" target="_blank" class="scrollto nav-link">Стратрезерв 2022<i class="bi bi-box-arrow-up-right"></i></a></li>
                      <li><router-link to="/young/forum" class="scrollto nav-link" v-on:click="collapseToggle">Молодежный форум</router-link></li>
                      <!-- <li><router-link to="/young/events" class="scrollto nav-link" v-on:click="collapseToggle">Мероприятия ППО</router-link></li> -->
                    </ul>
                  </li>
                  <li><a href="https://energy.212d.ru/" target="_blank" class="scrollto nav-link">ДОЛ Энергетик</a></li>
                  <li><router-link to="/partner" class="scrollto nav-link" v-on:click="collapseToggle">Партнеры</router-link></li>
                </ul>
              </div>
          </div>
        </div>
        <div id="topAuth" class="social-links me-3">
          <span v-if="isAuthenticated">
            <div class="d-flex">
              <span v-if="isAdmin">
                <router-link to="/concatination/management" title="Управление">
                  <i class="bi bi-gear"></i>
                </router-link>
              </span>
              <a href="#" @click="logout" title="Выйти">
                <i class="bi bi-box-arrow-right"></i>
              </a>
            </div>
          </span>
          <span v-else>
            <router-link to="/login" class="p-0" title="Войти">
              <i class="bi bi-person-square"></i>
            </router-link>
          </span>
        </div>
      </nav>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { server } from "@/utils/helper";
import router from "@/router";

export default {
  name: "Navbar",
  data() {
    return {
      isAdmin: '',
    };
  },
  computed : {
    isAuthenticated : function(){ return this.$store.getters.isAuthenticated},
  },
  created() {
    this.getAdmin();
  },
  methods:{
    // eslint-disable-next-line
    async getAdmin() {
      // eslint-disable-next-line
      const role = await this.compare(localStorage.getItem('user-token'));
      if (role=="ADMIN") {
        this.isAdmin = true;
      }
      else {
        this.isAdmin = false;
      }
    },
    collapseToggle() {
      if (document.querySelector('#navbarSupportedContent').classList.contains('show')) {
        document.querySelector('#navbarSupportedContent').classList.remove('show');
      }
    },
    logout: function () {
        this.isAdmin = false;
        this.$store.dispatch('AUTH_LOGOUT')
        .then(() => {
          this.$router.push('/home')
        })
      },
    compare(token) {
      return new Promise((resolve, reject) => {
        axios({url: `${server.baseURL}/admin/users/compare`, data: {token: token}, method: 'POST' })
        .then(resp => {
            if (resp != null) {
              resolve(resp.data)
            }
            else {
              localStorage.removeItem('user-token')
              reject('Подтверждение не прошло')
            }
        })
        .catch(err => {
            localStorage.removeItem('user-token')
            reject(err)
            })
        })
    },
    manage() {
        router.push({ name: 'Management' })
    },
  },
}
</script>

<style lang="scss">
#topAuth {
  position: absolute;
  right: 0em;
  top: 0.75em;
}

#topAuth i {
  font-size: 1.25em;
}

.nav-link {
  padding-left: 0.5em;
  padding-right: 0.5em;
}

.navbar {
  padding: 0;
}

.navbar ul {
  margin: 0;
  padding: 0;
  display: flex;
  list-style: none;
}

.navbar>ul>li>a {
  padding: 10px;
}

.navbar a,
.navbar a:focus {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #fff;
  white-space: nowrap;
  transition: 0.3s;
  font-weight: 600;
  color: #2c3e50;
}

@media (min-width: 681px) {
  .navbar .dropdown ul {
    display: block;
    position: absolute;
    top: calc(100% + 30px);
    margin: 0;
    padding: 0;
    z-index: 99;
    opacity: 0;
    visibility: hidden;
    background: #fff;
    box-shadow: 0px 0px 10px;
    transition: 0.3s;
  }

  .navbar .dropdown:hover>ul {
    opacity: 1;
    top: 100%;
    visibility: visible;
  }

  .mobileHide {
    display: block;
  }
}

@media (max-width: 680px) {
  .navbar .dropdown ul {
    display: none;
    position: relative;
    margin: 0;
    padding: 0;
    box-shadow: 0px 0px 10px;
    transition: 0.3s;
  }

  .navbar .dropdown:hover>ul {
    display: block;
    top: 100%;
  }

  .mobileHide {
    display: none;
  }
}

.navbar .dropdown ul a i {
  margin-left: 0.33rem;
  font-size: 12px;
}

.navbar .dropdown ul a:hover,
.navbar .dropdown ul .active:hover,
.navbar .dropdown ul li:hover>a {
  color: #fff;
}

#nav {
  text-align: center;
  background-color: #e3f2fd;
  margin-bottom: 1em;
}

#nav a:hover {
  color: rgb(82,116,161);
  font-weight: 600;
  background: #e3f2fd;
}

#nav a.active,
#nav a.router-link-exact-active {
  color: #e3f2fd;
  font-weight: 600;
  background: rgb(82,116,161);
  border: 1em;
}

a {
  text-decoration: none;
}

i {
  font-size: 1rem;
}

img {
  display: inline-block;
  height: auto;
  max-width: 50%;
}

#topbar {
  background: rgb(82,116,161);
  font-size: 12px;
  color: rgba(255, 255, 255, 0.6);
}

#topbar .contact-info a {
  line-height: 0;
  color: rgba(255, 255, 255, 0.6);
  transition: 0.3s;
}

#topbar .contact-info:hover {
  color: #fff;
}

#topbar .contact-info a:hover {
  color: #fff;
}
</style>
