<template>
  <div class="d-flex align-items-center justify-content-center">
  <form class="m-2" @submit.prevent="login">
    <h5>Авторизация</h5>
    <div class="form-floating">
      <input pattern=".{6,}" required title="Минимум 6 символов"
      v-model="user" type="text" class="form-control" placeholder="Введите логин">
      <label>логин</label>
    </div>
    <div class="form-floating">
      <input pattern=".{6,}" required title="Минимум 6 символов"
      v-model="password" type="password" class="form-control" placeholder="Введите пароль">
      <label>пароль</label>
    </div>
    <button id="btnLogin" class="btn btn-lg w-100 mb-2" type="submit">ВХОД</button>
    <div v-if="errors.length">
      <div v-for="error in errors" :key="error" class="alert alert-danger" role="alert">
        {{ error }}
      </div>
    </div>
    <div v-if="success">
      <div class="alert alert-success" role="alert">
        {{ success }}
      </div>
    </div>
  </form>
</div>
</template>

<style lang="scss" scoped>
#btnLogin:hover {
  color: #e3f2fd;
  font-weight: 600;
  background: rgb(82,116,161);
}

#btnLogin {
  color: rgb(82,116,161);
  font-weight: 600;
  background: #e3f2fd;
}
.form-floating {
  margin-bottom: 0.5rem;
}
</style>

<script>
import router from "@/router";

export default {
  data(){
      return {
        user : "",
        password : "",
        errors: [],
        success: ''
      }
    },
  methods: {
    login: function () {
      this.errors = [];
      this.success = '';
      const { user, password } = this
      this.$store.dispatch('AUTH_REQUEST', { user, password })
      .then(async response => {
        if (response.data.response.status === 201 || response.data.response.status === 202) {
          this.success = response.data.response.message;
          setTimeout(this.navigate, 3000);
        }
      })
      .catch(async response => {
        let errorResponse = await response.message;
        this.errors.push(errorResponse);
      });
    },
    navigate() {
      router.push({ name: "home" })
    }
  }
}
</script>