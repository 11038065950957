<template>
  <div class="container col-md-12 ">
    <div class="card align-self-center p-2">
      <button class="btn btn-outline-success float-left mb-2" v-on:click="navigate()">
        Вернуться назад
      </button>
      <h3 class="mb-4"> Редактирование сущности</h3>
      <form id="create-user-form" @submit.prevent="editEntity">
        <div class="form-group row mb-2"
        v-if="type=='blog' || type=='extrablog' || type=='card' || type=='miniblog' || type=='accordion' || type=='gallery' || type=='files' || type=='tab'">
          <label class="col-sm-2 col-form-label">Заголовок</label>
          <div class="col-sm-10">
            <input type="text" class="form-control"
            v-model="entity.title" name="title">
          </div>
        </div>
        <div class="form-group row mb-2"
        v-if="type=='blog' || type=='extrablog'">
          <label class="col-sm-2 col-form-label">Дата</label>
          <div class="col-sm-10 d-flex">
            <input type="text" class="form-control me-2"
            :value="new Date(entity.date).toLocaleDateString()" disabled>
            <input type="date" class="form-control"
            v-model="entity.date" name="date">
          </div>
        </div>
        <div class="form-group row mb-2"
        v-if="type=='blog' || type=='extrablog' || type=='card' || type=='miniblog' || type=='gallery'">
          <label class="col-sm-2 col-form-label">Изображение</label>
          <div class="col-sm-10 d-flex">
            <input id="photo" type="file" accept="image/*" class="form-control me-2" name="photo">
            <a class="btn btn-success float-right" v-on:click="pushPhoto()">
              Прикрепить
            </a>
          </div>
        </div>
        <div class="form-group row mb-2"
        v-if="type=='blog' || type=='extrablog' || type=='card' || type=='miniblog' || type=='accordion' || type=='text' || type=='tab' || type=='gallery'">
          <label class="col-sm-2 col-form-label">Основной текст</label>
          <div class="col-sm-10">
            <VueEditor :customModules="customModulesForEditor" :editorOptions="editorSettings" class="form-control text-control"
            v-model="entity.text" name="text"/>
          </div>
        </div>
        <div class="form-group row mb-2 detail-control"
        v-if="type=='blog' || type=='extrablog' || type=='card'">
          <label class="col-sm-2 col-form-label">Подробный текст</label>
          <div class="col-sm-10">
            <VueEditor :customModules="customModulesForEditor" :editorOptions="editorSettings" class="form-control detail-control-edit"
            v-model="entity.detail" name="detail"/>
          </div>
        </div>
        <hr/>
        <div class="form-group row mb-4">
          <label for="staticEmail" class="col-sm-2 col-form-label">Контейнер</label>
          <div class="col-sm-5">
            <input type="text" class="form-control" :value="entity.container.group" disabled>
          </div>
          <div class="col-sm-5">
            <select class="form-select" v-model="entity.container" name="tracker" required>
              <option v-for="container in containers" :value="container.group" :key="container.id">
                {{ container.group }}
              </option>
            </select>
          </div>
        </div>
        <div class="btn-toolbar justify-content-between">
          <button class="btn btn-outline-success float-left" v-on:click="navigate()">
            Вернуться назад
          </button>
          <button class="btn btn-success float-right" type="submit">
            Редактировать
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { server } from "@/utils/helper";
import axios from "axios";
import router from "@/router";
import {VueEditor} from 'vue3-editor';

export default {
  data() {
    return {
      id: 0,
      containers: [],
      entity: {},
      type: ''
    };
  },
  created() {
    this.id = this.$route.params.id;
    this.type = this.$route.params.type;
    this.getContainers();
    this.getEntity();

  },
   methods: {
    getContainers() {
      axios
      .get(`${server.baseURL}/admin/management/containers`)
        .then(data => (this.containers = data.data));
    },
    editEntity() {
    let entityData = {
      title: this.entity.title,
      date: this.entity.date,
      photo: this.entity.photo,
      text: this.entity.text,
      detail: this.entity.detail,
      container: this.entity.container,
    };
    axios
     .put(`${server.baseURL}/admin/management/editentity/${this.id}`, entityData)
     .then(() => {
      router.push({ name: 'Management' })
     })
   },
   getEntity() {
    axios
     .get(`${server.baseURL}/admin/management/getentity/${this.id}`)
     .then(data => ( this.entity = data.data ))
   },
   pushPhoto() {
      this.file = document.querySelector('#photo').files[0];
      let formData = new FormData();
      formData.append('image', this.file);
      axios.post(`${server.baseURL}/files`,
        formData,
        {
          headers: {'Content-Type': 'multipart/form-data'}
        }).then(response => {
          this.entity.photo = response.data.data.filename;
        }).catch(response => {
          return console.log(response);
        });
    },
   navigate() {
    router.push({ name: 'Management' })
   }
  },
  components: {
    VueEditor,
  },
};
</script>
