/* eslint-disable */
import { createStore } from 'vuex'
import axios from "axios";
import { server } from "@/utils/helper";

const store = createStore({
  state: {
    token: localStorage.getItem('user-token') || '',
    status: '',
  },
  getters: {
    isAuthenticated: state => !!state.token,
    authStatus: state => state.status,
  },
  actions: {
    AUTH_REQUEST ({commit, dispatch}, user) {
      return new Promise((resolve, reject) => {
          commit('AUTH_REQUEST')
          axios({url: `${server.baseURL}/admin/users/login`, data: user, method: 'POST' })
          .then(resp => {
              if (resp.data.result && resp.data.response.status == 202) {
                const token = resp.data.result
                localStorage.setItem('user-token', token)
                localStorage.setItem('time-token', +new Date())
                axios.defaults.headers.common['Authorization'] = token
                commit('AUTH_SUCCESS', token)
                resolve(resp)
              }
              else {
                commit('AUTH_ERROR', resp.data.response)
                localStorage.removeItem('user-token')
                localStorage.removeItem('time-token')
                reject(resp.data.response)
              }
          })
          .catch(err => {
              commit('AUTH_ERROR', err)
              localStorage.removeItem('user-token')
              localStorage.removeItem('time-token')
              reject(err)
          })
      })
    },
    AUTH_LOGOUT ({commit, dispatch}) {
      return new Promise((resolve, reject) => {
          commit('AUTH_LOGOUT')
          localStorage.removeItem('user-token')
          localStorage.removeItem('time-token')
          delete axios.defaults.headers.common['Authorization']
          resolve()
      })
    }
  },
  mutations: {
    AUTH_REQUEST (state) {
      state.status = 'loading'
    },
    AUTH_SUCCESS (state, token) {
      state.status = 'success',
      state.token = token
    },
    AUTH_LOGOUT (state) {
      state.status = '',
      state.token = ''
    },
    AUTH_ERROR (state) {
      state.status = 'error',
      state.token = ''
    },
  }
})

export default store;