<template>
  <Navadmin></Navadmin>
  <div class="row justify-content-center m-0">
      <h3>
        Список ролей
        <router-link
          :to="{ name: 'CreateRole'}"
          >
            <button type="button" class="btn btn-sm btn-outline-success">
              +
            </button>
        </router-link>
      </h3>
  </div>
  <div v-if="roles.length === 0">
    <blockquote >роли отсутствуют</blockquote>
  </div>
  <div id="roleBlock" class="row justify-content-center m-0">
    <div class="col-md-4" v-for="role in roles" :key="role.id">
      <div class="card m-1">
        <div class="card-header d-flex justify-content-between">
          <p class="m-0">#{{ role.id }}</p>
          <p class="m-0">{{ role.role }}</p>
          <div>
            <router-link :to="{ name: 'EditRole', params: { id: role.id } }">
              <i class="bi bi-pencil"/>
            </router-link>
            <a class="apointer ms-3" v-on:click="deleteRole(role.id, $event)">
              <i class="bi bi-trash" />
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style>
#roleBlock i,
#roleBlock a {
  color: black;
}
</style>

<script>
import { server } from "@/utils/helper";
import axios from "axios";
import Navadmin from '@/components/Navadmin.vue'

export default {
  data() {
    return {
      roles: [],
    };
  },
  created() {
    this.fetchRoles();
  },
  methods: {
    fetchRoles() {
      axios
        .get(`${server.baseURL}/admin/roles`)
        .then(data => (
          this.roles = data.data
          ));
    },
    deleteRole(id, event) {
      axios
        .delete(`${server.baseURL}/admin/roles/delete/${id}`)
        .then(() => {
          event.target.parentElement.parentElement.parentElement.style.visibility = 'hidden';
        });
    },
  },
  components: {
    Navadmin,
  },
};
</script>
