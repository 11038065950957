<template>
  <div class="col-3 align-middle">
    <a :href="entity.text" target="_blank">
      <img :src="serverAdr+'/files/'+entity.photo" crossorigin="anonymous" class="img-fluid p-2" :alt="entity.title"/>
    </a>
  </div>
</template>

<style>

</style>

<script>
import { server } from "@/utils/helper";

export default {
  data() {
    return {
      serverAdr: server.baseURL,
    };
  },
  props: {
    entity: {
      type: Object,
      required: false
    }
  },
}
</script>