<template>
  <div v-if="zip">
    <form class="attach-zip">
      <a :href="attach.path" target="_blank">
        <div class="btn d-flex flex-column align-items-center m-0 p-0">
          <i class="bi bi-images zip"></i>
          <div v-if="title" class="attach-text text-center mx-2">{{attach.title}}</div>
        </div>
      </a>
    </form>
  </div>
  <div v-else>
    <form class="attach-links">
      <a :href="attach.path" target="_blank">
        <div class="btn ms-1 d-flex align-items-center m-0 p-0" data-toggle="tooltip" data-placement="top" :title="attach.title">
          <span v-if="attach.type=='doc'">
            <i class="bi bi-file-text-fill"></i>
          </span>
          <span v-if="attach.type=='img'">
            <i class="bi bi-file-image-fill"></i>
          </span>
          <span v-if="attach.type=='xls'">
              <i class="bi bi-file-ruled-fill"></i>
          </span>
          <span v-if="attach.type=='pdf'">
              <i class="bi bi-file-pdf-fill"></i>
          </span>
          <span v-if="attach.type=='zip'">
              <i class="bi bi-file-zip-fill"></i>
          </span>
          <span v-if="attach.type=='link'">
              <i class="bi bi-link"></i>
          </span>
          <span v-if="attach.type=='ppt'">
              <i class="bi bi-file-ppt-fill"></i>
          </span>
          <div v-if="title" class="attach-text text-start mx-2">{{attach.title}}</div>
        </div>
      </a>
    </form>
  </div>

</template>

<style>
.zip {
  font-size: 5rem;
  margin: 0;
}

.attach-zip {
  min-width: 15rem;
  max-width: 15rem;
}

.attach-text {
  font-size: small;
}

.attach-links a {
  color: rgb(82,116,161);
  line-height: 2px;
  transition: 0.3s;
}

.attach-links i {
  font-size: large;
}

.attach-links i.bi-file-text-fill:hover {
  color: blue;
}

.attach-links i.bi-file-image-fill:hover {
  color: yellowgreen;
}

.attach-links i.bi-file-ruled-fill:hover {
  color: green;
}

.attach-links i.bi-file-pdf-fill:hover {
  color: red;
}

.attach-links i.bi-file-zip-fill:hover {
  color: burlywood;
}

.attach-links i.bi-link:hover {
  color: blueviolet;
}
</style>

<script>
export default {
  props: {
    attach: {
      type: Object,
      required: false
    },
    title: {
      type: Boolean,
      default: true,
      required: false
    },
    zip: {
      type: Boolean,
      required: false
    }
  }
}
</script>