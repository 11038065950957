<template>
  <div class="container col-md-4 ">
    <div class="card align-self-center p-2">
      <button class="btn btn-outline-success float-left mb-2" v-on:click="navigate()">
        Вернуться назад
      </button>
   <h3 class="mb-4"> Редактирование вложения</h3>
   <form id="edit-user-form" @submit.prevent="editAttach">
    <div class="form-group row mb-2">
      <label class="col-sm-4 col-form-label">Заголовок</label>
      <div class="col-sm-8">
        <input type="text" class="form-control"
        v-model="attach.title" name="title">
      </div>
    </div>
    <div class="form-group row mb-2">
      <label class="col-sm-4 col-form-label">Тип</label>
      <div class="col-sm-8">
        <input type="text" class="form-control"
        v-model="attach.type" name="type">
      </div>
    </div>
    <div class="form-group row mb-2">
      <label class="col-sm-4 col-form-label">Путь</label>
      <div class="col-sm-8">
        <input type="text" class="form-control"
        v-model="attach.path" name="path">
      </div>
    </div>
    <div class="form-group row mb-4">
      <label for="staticEmail" class="col-sm-4 col-form-label">Сущность</label>
      <div class="col-sm-4">
        <input type="text" class="form-control" :value="attach.entity.id" disabled>
      </div>
      <div class="col-sm-4">
        <select class="form-select" v-model="attach.entity" name="attach" required>
          <option v-for="entity in entities" :value="entity.id" :key="entity.id">
            {{ entity.id }}
          </option>
        </select>
      </div>
    </div>
    <div class="btn-toolbar justify-content-between">
      <button class="btn btn-outline-success float-left" v-on:click="navigate()">
        Вернуться назад
      </button>
      <button class="btn btn-success float-right" type="submit">
        Редактировать
      </button>
    </div>
   </form>
  </div>
 </div>
</template>

<script>
import { server } from "@/utils/helper";
import axios from "axios";
import router from "@/router";

export default {
  data() {
    return {
      id: 0,
      entities: [],
      attach: {},
    };
  },
  created() {
    this.id = this.$route.params.id;
    this.geEntities();
    this.getAttach();

  },
   methods: {
    geEntities() {
      axios
      .get(`${server.baseURL}/admin/management/entities`)
        .then(data => (this.entities = data.data));
    },
    editAttach() {
    let attachData = {
      title: this.attach.title,
      type: this.attach.type,
      path: this.attach.path,
      entity: this.attach.entity,
    };
    axios
     .put(`${server.baseURL}/admin/management/editattach/${this.id}`, attachData)
     .then(() => {
      router.go(-1)
     })
   },
   getAttach() {
    axios
     .get(`${server.baseURL}/admin/management/getattach/${this.id}`)
     .then(data => ( this.attach = data.data ))
   },
   navigate() {
    router.push({ name: 'Management' })
   }
  }
};
</script>