import { createRouter, createWebHistory } from "vue-router";
import store from '@/utils/store';
import axios from "axios";
import { server } from "@/utils/helper";

import MainView from "@/views/MainView.vue";
import LoginView from "@/views/LoginView.vue";
import BlogDetail from "@/components/entities/BlogDetail.vue";
import ErrorPage from "@/components/404.vue"

import Users from "@/views/admin/Users.vue";
import EditUser from "@/components/user/EditUser.vue";
import CreateUser from "@/components/user/CreateUser.vue";

import Roles from "@/views/admin/Roles.vue";
import EditRole from "@/components/role/EditRole.vue";
import CreateRole from "@/components/role/CreateRole.vue";

import Management from "@/views/admin/Management.vue";
import CreateContainer from "@/components/container/CreateContainer.vue";
import EditContainer from "@/components/container/EditContainer.vue";
import CreateEntity from "@/components/entity/CreateEntity.vue";
import EditEntity from "@/components/entity/EditEntity.vue";
import CreateAttach from "@/components/attach/CreateAttach.vue";
import EditAttach from "@/components/attach/EditAttach.vue";

const routes = [
  { path: "/404", name: "404", component: ErrorPage },
  { path: "/", name: "home", component: MainView },
  { path: "/home/news/:id", name: "newsdetail", component: BlogDetail },

  { path: "/about/history", name: "history", component: MainView },
  { path: "/about/structure", name: "structure", component: MainView },
  { path: "/about/company", name: "company", component: MainView },
  { path: "/about/entry", name: "entry", component: MainView },
  { path: "/about/gallery", name: "gallery", component: MainView },

  { path: "/activity/information", name: "information", component: MainView },
  { path: "/activity/organization", name: "organization", component: MainView },
  { path: "/activity/labor", name: "labor", component: MainView },
  { path: "/activity/legal", name: "legal", component: MainView },
  { path: "/activity/social", name: "social", component: MainView },
  { path: "/activity/creative", name: "creative", component: MainView },
  { path: "/activity/financial", name: "financial", component: MainView },
  { path: "/activity/law", name: "law", component: MainView },

  { path: "/documents/docsVEP", name: "docsVEP", component: MainView },
  { path: "/documents/docsZABCO", name: "docsZABCO", component: MainView },

  { path: "/ppo", name: "ppo", component: MainView },

  { path: "/young/forum", name: "forum", component: MainView },
  { path: "/young/events", name: "events", component: MainView },

  { path: "/partner", name: "partner", component: MainView },

  { path: "/login", name: "Login", component: LoginView},

  { path: "/concatination/users", name: "Users", component: Users },
  { path: "/concatination/users/create", name: "CreateUser", component: CreateUser },
  { path: "/concatination/users/edit/:id", name: "EditUser", component: EditUser },

  { path: "/concatination/roles", name: "Roles", component: Roles },
  { path: "/concatination/roles/create", name: "CreateRole", component: CreateRole },
  { path: "/concatination/roles/edit/:id", name: "EditRole", component: EditRole },

  { path: "/concatination/management", name: "Management", component: Management },
  { path: "/concatination/management/createcontainer", name: "CreateContainer", component: CreateContainer },
  { path: "/concatination/management/editcontainer/:id", name: "EditContainer", component: EditContainer },
  { path: "/concatination/management/createentity/:type", name: "CreateEntity", component: CreateEntity},
  { path: "/concatination/management/editentity/:type/:id", name: "EditEntity", component: EditEntity},
  { path: "/concatination/management/createattach", name: "CreateAttach", component: CreateAttach},
  { path: "/concatination/management/editattach/:id", name: "EditAttach", component: EditAttach},
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

function compare(token) {
  return new Promise((resolve, reject) => {
    axios({url: `${server.baseURL}/admin/users/compare`, data: {token: token}, method: 'POST' })
    .then(resp => {
        if (resp != null) {
          resolve(resp.data)
        }
        else {
          localStorage.removeItem('user-token')
          reject('Подтверждение не прошло')
        }
    })
    .catch(err => {
        localStorage.removeItem('user-token')
        reject(err)
      })
  })
}

router.beforeEach(async (to, from, next) => {
  //console.log(to.path)
  if (to.path.indexOf("/concatination")==0) {
    const role = await compare(localStorage.getItem('user-token'));
    if (role == "ADMIN") {
      next();
    }
    else {
      next("/404");
    }
  }
  else if (!routes.find(route => route.path === to.path) && to.path.indexOf("/home/news/")!=0) {
    next('/404');
  }
  else {
    const securityPages = ['/activity/labor','/activity/legal','/activity/financial','/documents/docsZABCO'];
    const authRequired = securityPages.includes(to.path);
    if (authRequired) {
      if (store.getters.isAuthenticated) {
        next();
        return;
      }
      next('/login');
    }
    else {
      next();
    }
  }
})

export default router;