<template>
  <div class="card border-light col">
    <div class="d-flex flex-nowrap">
      <div class="flex-shrink-1">
        <img id="cardImg" :src="serverAdr+'/files/'+entity.photo" crossorigin="anonymous" class="img-fluid rounded-start">
      </div>
      <div class="card-body">
        <h6 class="text-start"><strong>{{entity.title}}</strong></h6>
        <p class="paragraphCARD" v-html="entity.text"></p>
        <p class="detailCARD" v-html="entity.detail"></p>
      </div>
    </div>
  </div>
</template>

<style>
#cardImg {
  min-width: 100px !important;
}

.paragraphCARD {
  text-align: justify;
  font-style: italic;
}
.detailCARD {
  text-align: justify;
}
</style>

<script>
import { server } from "@/utils/helper";

export default {
  data() {
    return {
      serverAdr: server.baseURL,
    };
  },
  props: {
    entity: {
      type: Object,
      required: false
    }
  },
}
</script>