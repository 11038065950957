<template>
  <div id="pageBlog" class="d-flex flex-column justify-content-center align-items-center">
    <div>
      <div class="d-flex justify-content-between align-items-center mb-2">
        <h5>{{header}}</h5>
        <nav aria-label="Пример навигации по страницам">
          <ul class="pagination pagination-sm">
            <li :class="'page-item '+[pageNumber === 0 ? 'disabled' : '']">
              <a class="page-link" @click="prevPage" aria-label="Предыдущая">&laquo;</a>
            </li>
            <li v-for="page in pageCount" :key="page"
              :class="'page-item '+ [pageNumber === page-1 ? 'active' : '']"
            >
              <a :aria-label="page" @click="changePage(page-1)"
                v-if="Math.abs(page - pageNumber) < 3 || page == pageCount || page == 1"
                :class="'page-link '
                +[ page == pageCount && pageNumber != page-1 && Math.abs(page - pageNumber) > 3 ? 'last' : '']
                + [page == 1 && pageNumber != page-1 && Math.abs(page - pageNumber) > 3 ? 'first' : '']"
              >
                {{page}}
              </a>
            </li>
            <li :class="'page-item '+[pageNumber >= pageCount -1 ? 'disabled' : '']">
              <a class="page-link" @click="nextPage" aria-label="Следующая">&raquo;</a>
            </li>
          </ul>
        </nav>
      </div>
      <div class="d-flex flex-column">
        <div v-for="entity in paginatedData" :key="entity.id">
          <div :class="'card border-light mb-4 order-'+entity.date">
            <div class="row g-0">
              <div class="col-md-4 pe-2">
                <img :src="serverAdr+'/files/'+entity.photo" crossorigin="anonymous" class="img-fluid rounded-start ">
              </div>
              <div class="col-md-8 d-flex flex-column justify-content-between p-1">
                <div class="d-flex flex-column justify-content-between p-0">
                  <p class="card-text dateBLOG text-muted text-end">{{new Date(entity.date).toLocaleDateString()}}</p>
                  <h6 class="card-title text-center mb-3"><strong>{{entity.title}}</strong></h6>
                  <p class="paragraphBLOG" v-html="entity.text"></p>
                </div>
                <div class="d-flex justify-content-between mb-0">
                  <div class="d-flex justify-content-start align-items-center" v-if="entity.attaches.length > 0">
                    Вложения:
                    <Attach v-for="attach in entity.attaches" :key="attach.id" :attach="attach" :title=false
                    class="ms-2 align-items-center"></Attach>
                  </div>
                  <div class="d-flex ms-auto">
                  <!-- <a :href="'/home/news/'+entity.id" class="btn btn-sm btn-detail">Подробнее</a> -->
                    <router-link :to="{ name: 'newsdetail', params: { id: entity.id }}" class="btn btn-sm btn-detail">Подробнее</router-link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <nav aria-label="Пример навигации по страницам">
      <ul class="pagination pagination-sm">
        <li :class="'page-item '+[pageNumber === 0 ? 'disabled' : '']">
          <a class="page-link" @click="prevPage" aria-label="Предыдущая">&laquo;</a>
        </li>
        <li v-for="page in pageCount" :key="page"
          :class="'page-item '+ [pageNumber === page-1 ? 'active' : '']"
        >
          <a :aria-label="page" @click="changePage(page-1)"
            v-if="Math.abs(page - pageNumber) < 3 || page == pageCount || page == 1"
            :class="'page-link '
            + [ page == pageCount && pageNumber != page-1 && Math.abs(page - pageNumber) > 3 ? 'last' : '']
            + [page == 1 && pageNumber != page-1 && Math.abs(page - pageNumber) > 3 ? 'first' : '']"
          >
            {{page}}
          </a>
        </li>
        <li :class="'page-item '+[pageNumber >= pageCount -1 ? 'disabled' : '']">
          <a class="page-link" @click="nextPage" aria-label="Следующая">&raquo;</a>
        </li>
      </ul>
    </nav>
  </div>
</template>

<style lang="scss">
.first:after {
  content:'...';
  display: inline-block;
}

.last:before {
  content: '...';
  position: relative;
  display: inline-block;
}

.page-link {
  color: black;
}

.page-link:hover {
  cursor: pointer;
  color: black;
  background-color: #e3f2fd;
}

.active>.page-link {
  background-color: rgb(82,116,161);
  border-color: rgb(82,116,161);
}

.active:hover>.page-link {
  background-color: rgb(82,116,161);
  border-color: rgb(82,116,161);
  color: white;
}

.btn-detail:hover {
  color: #e3f2fd;
  font-weight: 600;
  background: rgb(82,116,161);
}

.btn-detail {
  color: rgb(82,116,161);
  font-weight: 600;
  background: #e3f2fd;
}

.paragraphBLOG {
  display: block;
  text-overflow: ellipsis;
  word-wrap: break-word;
  overflow: hidden;
  max-height: 4.8em;
  line-height: 1.2em;
  -webkit-mask-image: linear-gradient(to bottom, black 85%, transparent 99%);
  mask-image: linear-gradient(to bottom, black 85%, transparent 99%);
}

.dateBLOG {
  font-size: small;
  margin: 0;
}

.detailBLOG {
  text-align: justify;
}
</style>

<script>
import Attach from '@/components/entities/Attach.vue';
import { server } from "@/utils/helper";

export default {
  data() {
    return {
      serverAdr: server.baseURL,
      pageNumber: 0,
    };
  },
  props: {
    entities: {
      type: Array,
      required: true
    },
    header: {
      type: String,
      required: false
    },
    size:{
      type:Number,
      required:false,
      default: 6
    },
  },
  computed: {
    pageCount(){
      let l = this.entities.length,
          s = this.size;
      return Math.ceil(l/s);
    },
    paginatedData(){
    const start = this.pageNumber * this.size,
          end = start + this.size;
    return this.entities.slice(start, end);
    },
  },
  methods:{
      nextPage(){
         this.pageNumber++;
      },
      prevPage(){
        this.pageNumber--;
      },
      changePage(page){
        this.pageNumber=page;
      }
  },
  components: {
    Attach,
  },
}
</script>