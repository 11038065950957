<template>
  <div class="d-flex align-items-center justify-content-center">
    <div class="m-2">
      <div class=" col-md-12">
          <i class="bi bi-code-slash" style="font-size: 3rem; color: rgb(82,116,161);"></i>
      </div>
      <div class=" col-md-12 mt-3">
          <p class="fs-3"> <span class="text-danger">Упс!</span> Данная страница не найдена</p>
          <p class="lead">
              Станица, которую вы ищете не существует.
          </p>
          <a id="redirectHome" href="/" class="btn btn-lg">Перейти на главную</a>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
#redirectHome:hover {
  color: #e3f2fd;
  font-weight: 600;
  background: rgb(82,116,161);
}

#redirectHome {
  color: rgb(82,116,161);
  font-weight: 600;
  background: #e3f2fd;
}
</style>