<template>
  <div class="vidgets mb-2">
    <h5>Приветственное слово председателя</h5>
    <div class="p-2">
      <img src="@/assets/simkinaev.png" class="img-fluid">
    </div>
    <div>
      <p class="speech mb-1">
        В современных условиях обострения противоречий между трудом и капиталом у трудящихся в еще большей степени появляется необходимость объединения для защиты своих интересов, поскольку профсоюзное движение является для них последним средством сопротивления такому развитию событий, при котором игнорируются их интересы...
      </p>
      <a data-bs-toggle="offcanvas" href="#offcanvasSpeech" aria-controls="offcanvasSpeech"
      class="btn btn-contact d-flex align-items-center justify-content-center mb-1">
        <p class="mb-0 text-middle">Подробнее</p>
      </a>
      <router-link to="/about/entry" class="btn btn-entry d-flex align-items-center justify-content-center mb-1">
          <h6 class="card-title"><strong>
          Вступить в профсоюз
          </strong></h6>
        </router-link>
    </div>
  </div>
  <div class="offcanvas offcanvas-top" tabindex="-1" id="offcanvasSpeech" aria-labelledby="offcanvasSpeechLabel">
    <div class="offcanvas-header p-1">
      <h5 class="offcanvas-title" id="offcanvasSpeechLabel">Приветственное слово председателя!</h5>
      <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Закрыть"></button>
    </div>
    <div class="offcanvas-body">
      <div class="p-1">
        <h6 class="card-title mb-3"><strong>
          Уважаемые посетители нашего сайта! Доброго времени суток!
        </strong></h6>
        <img src="@/assets/simkinaev.png" class="img-extra img-fluid"/>
        <p class="speechCanvas mt-3">В современных условиях обострения противоречий между трудом и капиталом у трудящихся в еще большей степени появляется необходимость объединения для защиты своих интересов, поскольку профсоюзное движение является для них последним средством сопротивления такому развитию событий, при котором игнорируются их интересы. В условиях рыночной экономики профсоюзы становятся едва ли не единственной организацией к кому идут за защитой работники, члены профсоюза.</p>
        <p class="speechCanvas mt-1">В соответствии с действующим законодательством профсоюзы создаются и действуют для представительства и защиты социально-трудовых прав и интересов граждан; обеспечения общих производственных, профессиональных, отраслевых интересов работников; удовлетворения их духовных и иных нематериальных потребностей.</p>
        <p class="speechCanvas mt-1">Профсоюзы являются равноправной стороной процессов социального партнерства. Заключая коллективные договоры и соглашения, профсоюзы принимают непосредственное участие в установлении и регулировании норм оплаты труда, разрешении коллективных и индивидуальных трудовых споров, в содействии занятости, сохранении и создании новых рабочих мест, в контроле за соблюдением законодательства о труде, охране труда.</p>
        <router-link to="/about/entry" class="btn btn-outline-success m-2">
          <h6 class="card-title"><strong>
          Вступай в профсоюз и отстаивай свои права вместе с нами!
          </strong></h6>
        </router-link>
      </div>
    </div>
  </div>
  <hr/>
  <div class="vidgets mb-2">
    <h5>Контакты</h5>
    <a href="https://yandex.ru/maps/-/CCURnJckDA" target="_blank"
      class="btn btn-contact d-flex align-items-center justify-content-left mb-1">
      <i class="bi bi-geo-alt-fill"></i>
      <p class="ms-1 mb-0 text-start">г. Чита, ул. Ленина, д. 90, оф. 37</p>
    </a>
    <a href="tel:+73022356957"
      class="btn btn-contact d-flex align-items-center justify-content-left mb-1">
      <i class="bi bi-phone-fill phone-icon"></i>
      <p class="ms-1 mb-0 text-start">+7 (3022) 35-69-57</p>
    </a>
    <a href="mailto:elprof@inbox.ru" target="_blank"
      class="btn btn-contact d-flex align-items-center justify-content-left mb-1">
      <i class="bi bi-envelope-fill"></i>
      <p class="ms-1 mb-0 text-start">elprof@inbox.ru</p>
    </a>
    <a href="https://t.me/elprof75" target="_blank"
      class="btn btn-contact d-flex align-items-center justify-content-left mb-1">
      <i class="bi bi-telegram"></i>
      <p class="ms-1 mb-0 text-start">Телеграм ЗабКО ВЭП</p>
    </a>
    <a href="https://vk.com/club211713611" target="_blank"
      class="btn btn-contact d-flex align-items-center justify-content-left mb-1">
      <i class="bi bi-bootstrap-fill"></i>
      <p class="ms-1 mb-0 text-start">Группа ВК ЗабКО ВЭП</p>
    </a>
  </div>
  <hr/>
  <div>
    <h5>Полезные ресурсы</h5>
    <a href="https://www.solidarnost.org/" target="_blank"
    class="btn d-flex align-items-center justify-content-left mb-1 p-1">
      <img src="@/assets/solidarnost.jpg" class="img">
      <p class="ms-1 mb-0 text-start">Газета "Cолидарность"</p>
    </a>
    <a href="https://www.elprof.ru/activity/informatsionnaya-rabota/massmedia/index.php" target="_blank"
    class="btn d-flex align-items-center justify-content-left mb-1 p-1">
      <img src="@/assets/vestnik.jpg" class="img">
      <p class="ms-1 mb-0 text-start">Журнал "Вестник Электропрофсоюза"</p>
    </a>
    <a href="https://proftv-online.ru/" target="_blank"
    class="btn d-flex align-items-center justify-content-left mb-1 p-1">
      <img src="@/assets/tv.jpg" class="img">
      <p class="ms-1 mb-0 text-start">Профсоюз-ТВ</p>
    </a>
    <a href="https://www.youtube.com/channel/UCNZ4S-umLGk-t-NanNBUnaw/videos?view_as=subscriber" target="_blank"
    class="btn d-flex align-items-center justify-content-left mb-1 p-1">
      <img src="@/assets/youtube.png" class="img">
      <p class="ms-1 mb-0 text-start">Видеотека ВЭП</p>
    </a>
  </div>
  <hr/>
  <div>
    <h5>Напишите нам</h5>
    <form id="messageForm" class="row g-3 mb-3" @submit.prevent="sendMessage">
      <div>
        <input v-model="fio" type="text" class="form-control" placeholder="ФИО" required>
      </div>
      <div>
        <input v-model="subject" type="text" class="form-control" placeholder="Тема обращения" required>
      </div>
      <div>
        <textarea v-model="text" class="form-control" placeholder="Текст обращения" rows="6" required></textarea>
      </div>
      <div class="d-flex justify-content-between p-0">
        <button class="btn me-2 btn-outline-danger" type="reset" >
          Сбросить
        </button>
        <button class="btn btn-post align-items-center justify-content-center m-0" type="submit">
          Отправить
        </button>
      </div>
    </form>
  </div>
</template>

<style>
.offcanvas {
  --bs-offcanvas-height: 100%;
}

.img-extra {
  float:left;
  margin-right: 2rem;
}

.speech {
  text-align: justify;
  font-size: smaller;
  text-indent: 1rem;
}

.speechCanvas {
  text-align: justify;
  text-indent: 2rem;
}

.resource {
  vertical-align: baseline;
  position: relative;
  height: 30px;
}

.btn-contact:hover {
  color: white;
  background: rgb(82,116,161);
}

.btn-contact {
  color: rgb(82,116,161);
}

.btn-entry:hover {
  color: white;
  background: green;
}

.btn-entry {
  color: green;
}

.btn-post:hover {
  background: white;
  color: rgb(82,116,161);
}

.btn-post {
  background: rgb(82,116,161);
  color: white;
}
</style>

<script>
export default {
  data() {
    return {
      fio: '',
      subject: '',
      text: '',
      body: ''
    };
  },
  methods: {
    sendMessage() {
      this.body = this.text + '. С Уважением, ' + this.fio
      document.querySelector('#messageForm').reset();
      window.open(`mailto:elprof@inbox.ru?subject=${this.subject}&body=${this.body}`);
    }
  },
}
</script>